import React, { useState, useEffect, useContext } from "react";
import { Box, Image, Heading, Text, Layer, Button, TextInput } from "grommet";
import { Link } from "react-router-dom";
import { Edit, Trash, Add, View } from "grommet-icons";

import { withRouter } from "react-router";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";

const Forums = ({ location, history }) => {
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [filtered, setFiltered] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const { userData, tenant } = useContext(AuthContext);

	useEffect(() => {
		const fo = tenant.ref.collection("forums");
		let unsubscribe;
		if (userData.role === "Admin") {
			unsubscribe = fo.onSnapshot((querySnapshot) => {
				Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const postData = doc.data();
						const img = doc.data().image;
						const url = img ? await storage().ref(img).getDownloadURL() : "";
						return { id: doc.id, ...postData, image: url };
					})
				).then((res) => {
					// console.log(res[0])
					setLoading(false);
					setData(res);
					setFiltered(res);
				});
			});
		} else {
			unsubscribe = fo.where("teacherRef", "array-contains", userData.ref).onSnapshot((querySnapshot) => {
				Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const postData = doc.data();
						const img = doc.data().image;
						const url = img ? await storage().ref(img).getDownloadURL() : "";
						return { id: doc.id, ...postData, image: url };
					})
				).then((res) => {
					// console.log(res[0])
					console.log(res);
					setLoading(false);
					setData(res);
					setFiltered(res);
				});
			});
		}

		return () => {
			unsubscribe();
		};
	}, []);

	useEffect(() => {
		data && setFiltered(data.forums);
	}, [data]);

	return (
		<Box>
			<Box background="white" overflow="hidden" round="small" elevation="medium">
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
					<Heading color="gray" level="3" margin="none">
						Listado de Foros
					</Heading>

					<Box justify="end" gap="medium" direction="row" flex="grow" wrap>
						<Box flex="grow">
							<TextInput placeholder="Buscar..." type="search" onChange={(ev) => setFiltered([...data.forums.filter((el) => el.name.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
						</Box>
						{userData.role === "Admin" && <Button primary onClick={() => history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/forums/newforum`)} icon={<Add />} label="Nuevo Foro" />}
					</Box>
				</Box>

				<Box>
					{data && (
						<ReactTable
							data={filtered}
							defaultPageSize={10}
							loading={loading}
							loadingText="Cargando Foros..."
							previousText="Foros Anteriores"
							nextText="Foros Siguientes"
							noDataText="Sin Foros"
							pageText="Página"
							ofText="de"
							rowsText="filas"
							columns={[
								{
									Header: "",
									headerStyle: { padding: 15 },
									id: "image",
									sortable: false,
									width: 100,
									style: { display: "flex", justifyContent: "center", padding: 15 },
									accessor: (datum) => (
										<Box background="light-3" border="all" height="60px" width="60px" round="full" overflow="hidden">
											<Image fit="cover" src={datum.image && datum.image} />
										</Box>
									),
								},
								{
									Header: "Título",
									headerStyle: { padding: 15 },
									id: "title",
									style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
									accessor: (datum) => (
										<Box>
											<Text>{datum.name}</Text>
										</Box>
									),
								},
								{
									Header: "Hilos Activos",
									id: "topics",
									headerStyle: { padding: 15 },
									width: 150,
									style: { display: "flex", justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (datum.topics ? datum.topics : 0),
								},
								{
									Header: "Moderar",
									headerStyle: { padding: 15 },
									id: "manage",
									width: 120,
									resizable: false,
									sortable: false,
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (
										<Link to={{ pathname: `${process.env.PUBLIC_URL + "/" + tenant.url}/forums/manageforum/${datum.id}` }}>
											<Button fill color="gray" icon={<View />} hoverIndicator />
										</Link>
									),
								},
								{
									Header: "Editar",
									headerStyle: { padding: 15 },
									id: "edit",
									width: 100,
									resizable: false,
									sortable: false,
									show: userData.role === "Admin",
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (
										<Link to={{ pathname: `${process.env.PUBLIC_URL + "/" + tenant.url}/forums/editforum/${datum.id}` }}>
											<Button fill color="gray" icon={<Edit />} hoverIndicator />
										</Link>
									),
								},
								{
									Header: "Eliminar",
									headerStyle: { padding: 15 },
									id: "delete",
									width: 100,
									resizable: false,
									sortable: false,
									show: userData.role === "Admin",
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (
										<Button
											color="gray"
											hoverIndicator
											icon={<Trash />}
											onClick={() => {
												toogleDeleteModal(!deleteModal);
												setContentIDToDelete(datum.id);
											}}
										/>
									),
								},
							]}
						/>
					)}
				</Box>
			</Box>

			{deleteModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea eliminar este foro?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el foro no podrá ser recuperado</Text>
						</Box>

						<Box direction="row" justify="between" width="100%" margin={{ top: "medium" }}>
							<Button label="Cancel" onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type="submit"
								label="Eliminar"
								color="status-critical"
								primary
								onClick={() => {
									tenant.ref.collection("forums").doc(contentIDToDelete).delete();
									toogleDeleteModal(!deleteModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default withRouter(Forums);
