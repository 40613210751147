import { Box, Button, Heading, Layer, Text, TextInput } from "grommet";
import { Download, Flag, FormViewHide, Trash, View } from "grommet-icons";
import React, { useContext, useEffect, useState } from "react";

import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { useHistory, useParams } from "react-router";

import AdvanceModal from "../../components/AdvanceModal";
import InviteModal from "../../components/InviteModal";
import AuthContext from "../../context/AuthContext";

import { CSVLink } from "react-csv";
import XLSX from "xlsx";

const CoursesAssigned = () => {
	const { userData, tenant } = useContext(AuthContext);
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [filtered, setFiltered] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");
	const [dataToDownload, setDataToDownload] = useState([]);
	const [usersModal, setUsersModal] = useState({ status: false, data: {} });
	const [viewCourse, setViewCourse] = useState(false);
	const [data, setData] = useState([]);
	const [course, setCourse] = useState({});
	const history = useHistory();
	const { id } = useParams();
	const [ex, setExport] = useState([]);

	useEffect(() => {
		tenant.ref
			.collection("courses")
			.doc(id)
			.get()
			.then((doc) => {
				setCourse({ ...doc.data(), ref: doc.ref });
			});
	}, []);

	useEffect(() => {
		tenant.ref
			.collection("courses")
			.doc(id)
			.collection("courseavs")
			.onSnapshot(async (querySnapshot) => {
				const res = await Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const original = await doc.data().courseav.get().then((res) => ({ course: res.data() }));
						original?.course?.userEmail === "jessica.lopez@solviastores.com" && console.log(doc.data(), original);
						return { ...doc.data(), id: doc.id, ref: doc.ref, ...original };
					})
				).then((res) => res);
				// console.log(res);
				setData(res);
				setFiltered(res);
				setExport(getDataToDownload(res));
			});
	}, []);

	const hideCourse = (courseID) => {
		const originalItem = data.find((el) => el.id === courseID);
		originalItem.courseav
			.update({
				visible: !originalItem.visible,
			})
			.then((res) => {
				originalItem.ref
					.update({
						visible: !originalItem.visible,
					})
					.then((res) => { })
					.catch((err) => {
						alert(err);
					});
			})
			.catch((err) => {
				alert(err);
			});
	};

	const deleteCourse = (courseID) => {
		const originalItem = data.find((el) => el.id === courseID);
		originalItem.courseav
			.delete()
			.then((res) => {
				originalItem.ref
					.delete()
					.then((res) => { })
					.catch((err) => {
						alert(err);
					});
			})
			.catch((err) => {
				alert(err);
			});
	};

	const getDataToDownload = (data) => {
		const p = data.map((course) => ({ advance: course.course.advance, user: course.userName + " " + course.userSurname, email: course.userEmail }));

		const d = p.map((datum) => {
			const extra = datum.advance
				.map((section, i) => {
					const exams = section.contents
						.filter((el) => el.contentType === "EXAM")
						.map((ex, ei) => {
							const per = (ex.advance.reduce((acc, cur) => acc + (cur?.openAnswer ? 1 : cur.answers[cur.response - 1]?.correctAnswer ? 1 : 0), 0) / ex.advance.length) * 100;

							if (ex?.advance?.some(el => el.openAnswer)) {
								// Return an array of the exam open questions and the answers plus the total of the exam
								const openQuestions = ex?.advance?.filter(el => el.openAnswer)
								const questionsAnswerPairs = openQuestions?.reduce((acc, cur, i) => [...acc, { [cur.question]: cur.answers[0]?.text }], [])

								return [{ [`${section.name} ${i + 1} - Examen ${ei + 1}`]: `${Math.round(per * 100) / 100}%` }, ...questionsAnswerPairs];
							}
							return [{ [`${section.name} ${i + 1} - Examen ${ei + 1}`]: `${Math.round(per * 100) / 100}%` }];
						})?.reduce((acc, cur) => ({ ...acc, ...cur }), {})
					// );

					const fin = {
						[`${section.name} ${i + 1}`]: section.status,
						[`${section.name} ${i + 1} completed`]: section.contents.filter((el) => el.status === "COMPLETED").length,
						[`${section.name} ${i + 1} total`]: section.contents.length,
						...(Object.values(exams)?.reduce((acc, cur) => ({ ...acc, ...cur }), {}))
					};

					// console.log(fin)

					return fin;
				})
				.reduce((acc, cur) => ({ ...acc, ...cur }), {});
			// console.log(extra)

			return {
				// ...datum,
				email: datum.email,
				user: datum.user,
				startedAt: datum?.advance[0] ? datum?.advance[0]?.startedAt?.toDate ? moment(datum?.advance[0].startedAt.toDate()).format(" DD/MM/YYYY [a las] HH:mm ") : "" : "",
				completedAt: datum?.advance[0] ? datum?.advance[0]?.completedAt?.toDate ? moment(datum?.advance[0].completedAt.toDate()).format(" DD/MM/YYYY [a las] HH:mm ") : "" : "",
				completed: datum.advance.reduce((acc, cur) => acc + cur.contents.filter((c) => c.status === "COMPLETED").length, 0),
				total: datum.advance.reduce((acc, cur) => acc + cur.contents.length, 0),
				...extra,
				// ...datum.advance
				// 	.map((section, i) => ({
				// 		[`section ${i + 1}`]: section.status,
				// 	}))
				// 	.reduce((acc, cur) => [cur, ...acc], []),
			};
		});
		// console.log(d);
		setDataToDownload(d);
		return d;
	};

	const downloadXLSX = () => {
		const ws = XLSX.utils.json_to_sheet(dataToDownload);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Datos");
		XLSX.writeFile(wb, course.name + "-" + moment().format("DD-MM-YYTHH-MM-A") + ".xlsx");
	};

	// console.log(filtered, course);

	return (
		<Box>
			<Box background="white" overflow="hidden" round="small" elevation="medium">
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
					<Heading color="gray" level="4" margin="none">
						Listado de Convocados ({filtered.length})
					</Heading>

					<Box justify="end" gap="medium" direction="row" flex="grow" wrap>
						<Box flex="grow" width={{ max: "450px" }}>
							<TextInput
								placeholder="Buscar..."
								type="search"
								onChange={(ev) => setFiltered([...data.filter((el) => el.userEmail.toLowerCase().includes(ev.target.value.toLowerCase()))])}
							/>
						</Box>
						<CSVLink data={ex} filename={course.name + "-" + moment().format("DD-MM-YYTHH-MM-A") + ".csv"}>
							<Button primary icon={<Download />} label="Exportar Datos" />
						</CSVLink>
						<Box>
							<Button onClick={downloadXLSX} primary icon={<Download />} label="Exportar Excel" />
						</Box>
					</Box>
				</Box>

				<ReactTable
					data={filtered}
					defaultPageSize={10}
					// loading={loading}
					loadingText="Cargando Datos..."
					previousText="Convocados Anteriores"
					nextText="Convocados Siguientes"
					noDataText="Sin Convocatorias"
					pageText="Página"
					ofText="de"
					rowsText="filas"
					columns={[
						{
							Header: "Email",
							headerStyle: { padding: 15, fontSize: 16 },
							id: "email",
							style: { display: "flex", whiteSpace: "unset", padding: 15, fontSize: 14, alignItems: "center" },
							accessor: (datum) => datum.userEmail,
						},
						{
							Header: "Nombre",
							headerStyle: { padding: 15, fontSize: 16 },
							id: "name",
							style: { display: "flex", whiteSpace: "unset", padding: 15, fontSize: 14, alignItems: "center" },
							accessor: (datum) => datum.userName,
						},
						{
							Header: "Inicio",
							headerStyle: { padding: 15, fontSize: 16 },
							id: "initDate",
							width: 100,
							style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
							accessor: (datum) => (datum.initDate ? moment(datum.initDate.toDate()).format("DD-MM-YYYY") : ""),
						},
						{
							Header: "Cierre",
							headerStyle: { padding: 15, fontSize: 16 },
							id: "endDate",
							width: 100,
							style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
							accessor: (datum) => (datum.endDate ? moment(datum.endDate.toDate()).format("DD-MM-YYYY") : ""),
						},
						{
							Header: "Completados",
							headerStyle: { padding: 15, fontSize: 16 },
							id: "status",
							width: 150,
							style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
							accessor: (datum) =>
								datum.course?.advance?.reduce((acc, cur) => acc + cur.contents.filter((c) => c.status === "COMPLETED").length, 0) +
								" de " +
								datum.course?.advance?.reduce((acc, cur) => acc + cur.contents.length, 0),
						},
						{
							Header: "Ver Avance",
							headerStyle: { padding: 15, fontSize: 16 },
							id: "advance",
							width: 150,
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							accessor: (datum) => (
								<Button
									color="gray"
									hoverIndicator
									icon={<Flag />}
									onClick={() => {
										setViewCourse(datum.courseav);
									}}
								/>
							),
						},
						{
							Header: "",
							headerStyle: { padding: 15 },
							id: "visible",
							width: 50,
							resizable: false,
							sortable: false,
							show: userData.role === "Admin",
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							accessor: (datum) => (
								<Button
									color="gray"
									hoverIndicator
									icon={!datum.visible ? <FormViewHide /> : <View />}
									onClick={() => {
										hideCourse(datum.id);
									}}
								/>
							),
						},
						{
							Header: "",
							headerStyle: { padding: 15 },
							id: "delete",
							width: 50,
							resizable: false,
							sortable: false,
							show: userData.role === "Admin",
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							accessor: (datum) => (
								<Button
									color="gray"
									hoverIndicator
									icon={<Trash />}
									onClick={() => {
										// toogleDeleteModal(!deleteModal);
										// setContentIDToDelete(datum.id);
										deleteCourse(datum.id);
									}}
								/>
							),
						},
					]}
				/>
			</Box>

			{deleteModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea eliminar esta Convocatoria?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el contenido no podrá ser recuperado</Text>
						</Box>

						<Box direction="row" justify="between" margin={{ top: "medium" }}>
							<Button label="Cancel" onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type="submit"
								label="Eliminar"
								color="status-critical"
								primary
								onClick={() => {
									deleteCourse(contentIDToDelete);
									toogleDeleteModal(!deleteModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}

			{viewCourse && (
				<AdvanceModal
					closeCallback={() => setViewCourse(false)}
					advanceRef={viewCourse}
					submitCallback={(cb) => {
						setViewCourse(false);
					}}
				/>
			)}

			{usersModal.status && (
				<InviteModal
					closeCallback={() => setUsersModal({ status: false, data: {} })}
					submitCallback={(cb) => {
						setUsersModal({ status: false, data: {} });
					}}
				/>
			)}
		</Box>
	);
};

export default CoursesAssigned;
