import { Box, Button, Heading, Layer, Text } from "grommet";
import React, { useContext, useEffect, useState } from "react";

import { firestore } from "firebase";

import AuthContext from "../context/AuthContext";

const AdvanceModal = ({ single, closeCallback, submitCallback, courseId, advanceRef, forumId, topicId }) => {
	const { userData, tenant, customization } = useContext(AuthContext);
	const [checked, setChecked] = useState([]);
	const [data, setData] = useState({});
	const [course, setCourse] = useState({});
	const [body, setBody] = useState("");

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		advanceRef.get().then((doc) => {
			console.log("EL ADVANCE", doc.data());
			setData({ ...doc.data(), id: doc.id, ref: doc.ref });
			getDataContent(doc.data().courseRef);
		});
	};

	const getDataContent = async (ref) => {
		if (ref) {
			ref.get().then((doc) => {
				console.log(doc.data());
				setCourse({ ...doc.data(), id: doc.id, ref: doc.ref });
			});
		}
		// console.log(forumId, topicId);
		// tenant.ref
		// 	.collection("forums")
		// 	.doc(forumId)
		// 	.collection("topics")
		// 	.doc(topicId)
		// 	.collection("messages")
		// 	.orderBy("createdAt", "asc")
		// 	.onSnapshot(async (querySnapshot) => {
		// 		const d = await Promise.all(
		// 			querySnapshot.docs.map(async (doc) => {
		// 				const img = doc.data()?.user?.image;
		// 				const url = img
		// 					? await storage()
		// 							.ref(img)
		// 							.getDownloadURL()
		// 							.then((res) => res)
		// 							.catch((err) => "https://www.theglobaltreasurer.com/wp-content/uploads/2019/02/placeholder-person.jpg")
		// 					: null;
		// 				return { ...doc.data(), id: doc.id, ref: doc.ref, user: { ...doc.data().user, image: url } };
		// 			})
		// 		).then((res) => res);
		// 		console.log(d);
		// 		setData(d);
		// 	});
	};

	const sendMessage = () => {
		const variables = {
			body,
			user: {
				id: userData.id,
				name: userData.name,
				image: `${tenant.ref.id}/users/${userData.id}/profilePicture`,
			},
			createdAt: firestore.FieldValue.serverTimestamp(),
			topic: tenant.ref.collection("forums").doc(topicId),
		};

		tenant.ref
			.collection("forums")
			.doc(forumId)
			.collection("topics")
			.doc(topicId)
			.collection("messages")
			.add(variables)
			.then(() => {
				setBody("");
				tenant.ref
					.collection("forums")
					.doc(forumId)
					.collection("topics")
					.doc(topicId)
					.update({
						responses: firestore.FieldValue.increment(1),
						lastActive: firestore.FieldValue.serverTimestamp(),
					});
			});
	};

	const deleteComment = (ref) => {
		ref.delete()
			.then((res) => {
				tenant.ref
					.collection("forums")
					.doc(forumId)
					.collection("topics")
					.doc(topicId)
					.update({
						responses: firestore.FieldValue.increment(-1),
						lastActive: firestore.FieldValue.serverTimestamp(),
					})
					.then((res) => console.log(res))
					.catch((err) => console.log(err));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const editComment = () => {};

	return (
		<Layer full="vertical" position="left" onEsc={() => closeCallback()} onClickOutside={() => closeCallback()}>
			<Box width={{ min: "large" }} flex>
				<Box pad="small" height="80px" background="#f2f2f2" justify="between" direction="row" align="center">
					{/* <Heading color='gray' level='4' margin='none'>
						Selecciona el foro que quieres asignar a este curso
					</Heading> */}
					<Box flex width={{ max: "600px" }}>
						<Heading level="4" style={{ width: "100%", maxWidth: "100%" }} margin="10px">
							Avance de {data?.userName} {data?.userSurname} en el curso {data?.name}
						</Heading>
					</Box>
					<Box direction="row" gap="small" pad="small" margin={{ right: "10px" }}>
						<Button label="Cerrar" onClick={() => closeCallback()} />
						{/* <Button primary label="Añadir" onClick={() => submitCallback(checked)} /> */}
					</Box>
				</Box>
				<Box overflow={{ vertical: "scroll" }} flex pad="small" gap="small">
					<Box flex="grow">
						<Box gap="small">
							{data?.advance?.map((section, i) => {
								return (
									<Box width="large" gap="small" background="white" elevation="medium" pad="medium" round="small" key={i}>
										{/* <Box direction="row" justify="between" align="center">
											<Box direction="row" align="center">
												<Text margin={{ right: "15px" }}>{moment(topic.createdAt.toDate()).format("DD [de] MMMM [de] YYYY [a las] hh:mm")}</Text>
												<Button hoverIndicator onClick={() => editComment()} icon={<Edit />} />
												<Button hoverIndicator onClick={() => deleteComment(topic.ref)} icon={<Trash />} />
											</Box>
										</Box> */}
										<Heading level="4" margin="none">
											{section.name}
										</Heading>
										<Box>
											{section?.contents?.map((content, i) => {
												content.contentType === "EXAM" && console.log(content);
												return (
													<Box key={i}>
														<Text>{content.name}</Text>
														<Box margin="small" background={content.status === "NOT INITIATED" ? "gainsboro" : content.status === "INITIATED" ? "status-warning" : content.status === "FAILED" ? "status-critical" : "status-ok"} pad="small" round="small">
															<Box>
																<Text weight="bold" color={content.status === "NOT INITIATED" ? "black" : content.status === "INITIATED" ? "white" : "white"}>
																	{content.status === "NOT INITIATED" ? "Sin Comenzar" : content.status === "INITIATED" ? "Comenzado" : content.status === "FAILED" ? "Examen No Superado" : "Completado"}
																</Text>
															</Box>
															{content.contentType === "SCORM" && content.fullData && content.status !== "NOT INITIATED" && (
																<Box>
																	<Text color={content.status === "NOT INITIATED" ? "black" : content.status === "INITIATED" ? "white" : "white"}>
																		Contenidos Completados - {content.fullData.suspend_data.split(";")[1].split("=")[1]} de {content.fullData.suspend_data.split(";")[0].split("=")[1]} - Tiempo transcurrido {content.fullData.core.session_time}
																	</Text>
																</Box>
															)}
															{content.contentType === "EXAM" && (
																<Box>
																	<Text color={content.status === "NOT INITIATED" ? "black" : content.status === "INITIATED" ? "white" : "white"}>
																		{content.advance.reduce((acc, cur) => acc + (cur.answers[cur.response - 1]?.correctAnswer ? 1 : 0), 0)} respuestas correctas de {content.advance.length}
																	</Text>
																	<Text color={content.status === "NOT INITIATED" ? "black" : content.status === "INITIATED" ? "white" : "white"}>
																		Porcentaje conseguido: {Math.round((content.advance.reduce((acc, cur) => acc + (cur.answers[cur.response - 1]?.correctAnswer ? 1 : 0), 0) / content.advance.length) * 10000) / 100}% - Porcentaje para aprobar{" "}
																		{content.percentageToPass}%
																	</Text>
																	<Text color={content.status === "NOT INITIATED" ? "black" : content.status === "INITIATED" ? "white" : "white"}>
																		Intentos realizados: {content.tries} de {content.maxTries} máximos
																	</Text>
																</Box>
															)}
															{content.contentType === "EXAM" && (
																<Box background="#f3f3f3" margin={{ top: "small" }} pad="small" round="small" gap="small">
																	{content.advance.map((question, i) => {
																		return (
																			<Box key={i} gap="small">
																				<Text size="small">{question?.question}</Text>
																				<Box pad="5px" background="white" direction="row" gap="small" round="small" align="center">
																					<Box background={(question?.answers[question.response - 1]?.correctAnswer || question?.openAnswer) ? "status-ok" : "status-critical"} width="25px" height="25px" round="small" />
																					<Box flex>
																						<Text size="12px">{question?.answers[question.response - 1]?.text}</Text>
																					</Box>
																				</Box>
																			</Box>
																		);
																	})}
																</Box>
															)}
														</Box>
													</Box>
												);
											})}
										</Box>
									</Box>
								);
							})}
						</Box>
					</Box>
				</Box>
				{/* <Box height="60px" align="center" pad={{ horizontal: "small" }} direction="row">
					<TextInput value={body} onChange={(ev) => setBody(ev.target.value)} placeholder="Escribe aquí..." />
					<Button hoverIndicator onClick={() => sendMessage()} icon={<Send />} />
				</Box> */}
			</Box>
		</Layer>
	);
};

export default AdvanceModal;
