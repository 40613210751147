import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import {
    Box,
    Button,
    Heading,
    Text,
    TextInput,
    Select,
    CheckBox
} from 'grommet';
import { Close } from 'grommet-icons'

import { firestore } from 'firebase'

import { withRouter, useParams } from "react-router";
import axios from 'axios'
import AuthContext from '../../context/AuthContext';


const UserForm = ({ location, history }) => {

    const { id } = useParams()
    const { userData, tenant } = useContext(AuthContext)

    const [data, setData] = useState({
        name: "",
        surname: "",
        username: "",
        email: "",
        role: "User",
        usergroups: [],
        disabled: false,
        zonas: {
            "content": false,
            "itinerary": false,
            "profile": false,
            "focus": false,
            "forum": false,
            "smartlearning": false
        },
        blocked: false
    });

    const [userGroups, setUserGroups] = useState([])
    const [publicG, setPublicG] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUserGroups()
    }, [])


    const getUserGroups = () => {
        tenant.ref.collection("usergroups").get().then(async res => {
            const grps = await res.docs.map(group => ({ ...group.data(), ref: group.ref, id: group.id }))
            setUserGroups(grps.filter(x => !x.public))
            setPublicG(grps.filter(x => x.public))
            if (grps.find(x => x.public)) {
                setData({ ...data, usergroups: [...data?.usergroups, ...grps.filter(x => x.public)] })
            }
            id && getUser(grps)
            !id && setLoading(false)
        }).catch(err => {
            id && getUser([])
            !id && setLoading(false)
        })
    }

    const getUser = (grps) => {
        firestore().collection("users").doc(id).get().then(res => {
            const usergroups = res.data().usergroups || []
            setData({
                ...data,
                role: res.data().role || "User",
                name: res.data().name || "",
                surname: res.data().surname || "",
                email: res.data().email || "",
                usergroups: grps.filter(gr => usergroups.map(c => c.id).includes(gr.id)),
                zonas: res.data().zonas ? {
                    "content": res.data().zonas["content"] || false,
                    "itinerary": res.data().zonas["itinerary"] || false,
                    "profile": res.data().zonas["profile"] || false,
                    "focus": res.data().zonas["focus"] || false,
                    "forum": res.data().zonas["forum"] || false,
                    "challenges": res.data().zonas["challenges"] || false,
                    "smartlearning": res.data().zonas["smartlearning"] || false
                }: {
                    "content": false,
                    "itinerary": false,
                    "profile": false,
                    "focus": false,
                    "forum": false,
                    "challenges": false,
                    "smartlearning": false
                },
                blocked: res.data().blocked || false,
            })
            setLoading(false)
        })
    }

    const createUser = () => {

    }

    const updateUser = () => {

    }


    const newUser = () => {

        setLoading(true)
        const grpRefs = data.usergroups.map((gr, i) => gr.ref)

        const variables = {
            email: data.email,
            password: data.password,
            name: data.name,
            disabled: data.disabled
        }
        console.log(variables)
        if (data.password === null) { 
            alert("Debes introducir una contraseña para crear un usuario o seleccionar la creación automática de contraseña.")
            setLoading(false)
        } else if (data.password === data.repeatPassword) {
            axios.post("https://europe-west1-apd-formacion.cloudfunctions.net/createUser", {
                method: 'POST',
                body: JSON.stringify(variables)
            }).then((response) => {

                console.log('Success:', response)
                if (!response.data.code) {

                    // console.log("bien")
                    firestore().collection("users").doc(response.data).set({
                        ...data,
                        tenants: [tenant.ref],
                        image: `${tenant.id}/users/${response.data}/profilePicture`,
                        usergroups: grpRefs,
                    }).then(res => history.push(`${process.env.PUBLIC_URL}/${tenant.url}/users`))

                } if (response.data.code === "auth/invalid-password") {
                    alert("La contraseña debe tener al menos 6 caracteres")
                } if (response.data.code === "auth/invalid-email") {
                    alert("El formato del email no es el adecuado")
                } if (response.data.code === "auth/email-already-exists") {
                    alert("El email introducido ya se encuentra en la Base de Datos")
                }
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setLoading(false)
            })

            // firestore().collection("users").add(variables).then(res => {
            //     history.push("/users")
            // }).catch(err => console.log(err))

        } else {
            alert("La contraseña no coincide")
            setLoading(false)
        }
    }

    const editUser = () => {
        setLoading(true)

        const grpRefs = data.usergroups.map((gr, i) => gr.ref)

        const variables = {
            email: data.email,
            name: data.name,
            disabled: data.disabled,
            userID: id
        }
        if (data.password) {
            if (data.password === data.repeatPassword) {
                axios.post("https://europe-west1-apd-formacion.cloudfunctions.net/updateUser", {
                    method: 'POST',
                    body: JSON.stringify({...variables, password: data.password})
                }).then((response) => {
                    // console.log('Success:', response)
                    if (response.data.code === null) {
                        console.log()
                        firestore().collection("users").doc(response.data).set({
                            ...data,
                            ...variables,
                            password: data.password,
                            repeatPassword: data.password,
                            usergroups: grpRefs
                        })
                        // history.push("/users")
                    } if (response.data.code === "auth/invalid-password") {
                        alert("La contraseña debe tener al menos 6 caracteres")
                    } if (response.data.code === "auth/invalid-email") {
                        alert("El formato del email no es el adecuado")
                    } if (response.data.code === "auth/email-already-exists") {
                        alert("El email introducido ya se encuentra en la Base de Datos")
                    } if (response.data.code === "auth/invalid-uid") {
                        alert("El usuario que intenta editar no existe en la Base de Datos")
                    }
                    setLoading(false)
                    alert("Usuario editado")
                }).catch((error) => {
                    setLoading(false)
                })

            } else {
                alert("La contraseña no coincide")
                setLoading(false)
            }
        } else {
            firestore().collection("users").doc(id).update({
                ...data,
                ...variables,
                usergroups: grpRefs,
            }).then(res => {
                setLoading(false)
                history.push(`${process.env.PUBLIC_URL}/${tenant.url}/users`)
            })
            // axios.post("https://europe-west1-hermes-god.cloudfunctions.net/updateUser", {
            //     method: 'POST',
            //     body: JSON.stringify(variables)
            // }).then((response) => {
            //     console.log('Success:', response)
            //     if (response.data.code === null) {
            //         history.push("/users")
            //     } if (response.data.code === "auth/invalid-password") {
            //         alert("La contraseña debe tener al menos 6 caracteres")
            //     } if (response.data.code === "auth/invalid-email") {
            //         alert("El formato del email no es el adecuado")
            //     } if (response.data.code === "auth/email-already-exists") {
            //         alert("El email introducido ya se encuentra en la Base de Datos")
            //     } if (response.data.code === "auth/invalid-uid") {
            //         alert("El usuario que intenta editar no existe en la Base de Datos")
            //     }
            //     setLoading(false)
            // }).catch((error) => {
            //     console.log(error)
            //     setLoading(false)
            // })
        }
    }


    return (
        <Box direction="row" gap="medium">

            <Box basis="2/3" direction="column" gap="medium">

                {/* <Box>
                    <Heading level="3" margin="small">Nombre de usuario</Heading>
                    <Box round="medium" pad={{ left: "small", right: "small" }} elevation="large" background="light-2" >
                        <TextInput plain background="light-1" placeholder="Nombre de usuario" value={data.username} onChange={event => setData({ ...data, username: event.target.value })} />
                    </Box>
                </Box> */}


                <Box background="white" overflow="hidden" round="small" elevation="medium" >
                    <Box pad="small" >
                        <Heading color="gray" level="4" margin="none">Email</Heading>
                    </Box>
                    <Box pad="small" >
                        <TextInput placeholder="Email" value={data.email} onChange={event => setData({ ...data, email: event.target.value })} />
                    </Box>
                    <Box pad="small" >
                        <Heading color="gray" level="4" margin="none">Nombre</Heading>
                    </Box>
                    <Box pad="small">
                        <TextInput placeholder="Nombre" value={data.name} onChange={event => setData({ ...data, name: event.target.value })} />
                    </Box>
                    <Box pad="small" >
                        <Heading color="gray" level="4" margin="none">Apellidos</Heading>
                    </Box>
                    <Box pad="small">
                        <TextInput placeholder="Apellidos" value={data.surname} onChange={event => setData({ ...data, surname: event.target.value })} />
                    </Box>
                    {/* <Box pad="small" >
                        <Heading color="gray" level="4" margin="none">Store</Heading>
                    </Box>
                    <Box pad="small">
                        <TextInput placeholder="Store" value={data.store} onChange={event => setData({ ...data, store: event.target.value })} />
                    </Box>
                    <Box pad="small" >
                        <Heading color="gray" level="4" margin="none">Ref</Heading>
                    </Box>
                    <Box pad="small">
                        <TextInput placeholder="Ref" value={data.apiRef} onChange={event => setData({ ...data, apiRef: event.target.value })} />
                    </Box> */}
                </Box>


                {/* CONTRASEÑA */}
                <Box background="white" overflow="hidden" round="small" elevation="medium" >

                    <Box pad="small" >
                        <Heading color="gray" level="4" margin="none">Contraseña</Heading>
                        {id && <Text size="small">Deja las contraseñas en blanco si no quieres editarla</Text>}
                    </Box>
                    <Box pad="small">
                        <TextInput type="password" placeholder="Contraseña" value={data.password || ""} onChange={event => setData({ ...data, password: event.target.value })} />
                    </Box>

                    <Box pad="small" >
                        <Heading color="gray" level="4" margin="none">Repetir Contraseña</Heading>
                    </Box>
                    <Box pad="small">
                        <TextInput type="password" placeholder="Contraseña" value={data.repeatPassword || ""} onChange={event => setData({ ...data, repeatPassword: event.target.value })} />
                    </Box>
                </Box>
                {/* FIN CONTRASEÑA */}

            </Box>


            <Box basis="1/3" direction="column" gap="medium">

                {/* BOTONES PUBLICACION */}
                <Box direction="column" gap="small">
                    <Button
                        disabled={loading ? true : data.email !== "" ? false : true}
                        primary
                        label={!id ? loading ? "Creando usuario" : "Crear usuario" : loading ? "Actualizando usuario" : "Actualizar usuario"}
                        onClick={() => !id ? newUser() : editUser()} />
                </Box>
                {/* FIN BOTONES PUBLICACION */}

                {/* GRUPOS DE USUARIOS */}
                <Box background="white" overflow="hidden" round="small" elevation="medium" >
                    <Box pad="small" >
                        <Heading color="gray" level="4" margin="none">Grupos</Heading>
                    </Box>
                    <Box pad="small">
                        <Select
                            labelKey="name"
                            valueKey="id"
                            multiple
                            placeholder="Grupo de usuarios"
                            options={userGroups}
                            onChange={({ value: nextValue }) => setData({ ...data, usergroups: [...nextValue, ...publicG] })}
                            value={data.usergroups}
                        />
                    </Box>
                    <Box gap="small" pad="small">
                        {data.usergroups.map((el, i) => !el?.public && <Button onClick={() => setData({ ...data, usergroups: data.usergroups.filter(gr => gr.id !== el.id) })} key={i} color="black" icon={el?.public ? null : <Close />} primary label={el.name} disabled={el?.public} />)}
                    </Box>
                </Box>
                {/* FIN GRUPOS DE USUARIOS */}

                {/* ROL DE USUARIOS */}
                <Box background="white" overflow="hidden" round="small" elevation="medium" >
                    <Box pad="small" >
                        <Heading color="gray" level="4" margin="none">Rol del Usuario</Heading>
                    </Box>
                    <Box pad="small">
                        <Select
                            placeholder="Rol del Usuario"
                            options={["User", "Guest", "Teacher", "Coordinator", "Admin"]}
                            onChange={({ value: nextValue }) => setData({ ...data, role: nextValue })}
                            value={data.role}
                        />
                    </Box>
                </Box>
                {/* FIN ROL DE USUARIOS */}
                {/* ACCESOS APLICACIÓN */}
                <Box background="white" overflow="hidden" round="small" elevation="medium" >
                    <Box pad="small" >
                        <Heading color="gray" level="4" margin="none">Areas disponibles para usuario</Heading>
                    </Box>
                    <Box pad="small" >

                        {Object.keys(data.zonas).map((area, i) =>
                            <Box margin="small"
                                key={i}>
                                <CheckBox
                                    checked={data.zonas[area]}
                                    label={area}
                                    onChange={event => setData({ ...data, zonas: { ...data.zonas, [area]: !data.zonas[area] } })}
                                />
                            </Box>
                        )}

                    </Box>
                </Box>
                {/* FIN ACCESOS APLICACIÓN */}

                <Box background="white" overflow="hidden" round="small" elevation="medium" >
                    <Box pad="small" >
                        <Heading color="gray" level="4" margin="none">Acceso a la Aplicación</Heading>
                    </Box>
                    <Box pad="small" >
                        <CheckBox
                            checked={data.blocked}
                            label="Bloquear Usuario"
                            onChange={(event) => setData({ ...data, blocked: event.target.checked })}
                        />
                    </Box>
                </Box>


            </Box>




            {/* MODALES */}

            {/* {loadingMedia && (
                <Layer>
                    <Text>Cargando</Text>
                </Layer>
            )} */}

            {/* FIN MODALES */}
        </Box>
    )
}

export default UserForm;